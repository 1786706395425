import Layout from "../components/layout"
import Seo from "../components/seo"

import * as React from "react"
import EditForm from "../components/incidents/edit_form"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"


const EditPage = () => {
	if(!isLoggedIn() && typeof window != 'undefined') {
		navigate("/404")
		return (<></>)
	}

	const regex = new RegExp('id\=[0-9]+');
	let matches = [];
	if(typeof window != 'undefined') {
	   matches = regex.exec(document.location.search) || [];
	}
	
	let id = 0;
	if(matches[0]) {
		id = parseInt(matches[0].substring(3));
	} else {
		//return (<div>NOT FOUND</div>)
	}
	
	return (
		<Layout>
			<Seo title="PostNL Early Warning System" />
			<div className="container">
				<div className="row">
				<div className="col-md-8 col-sm-12">
					<EditForm id={id} />
				</div>
				</div>
			</div>
		</Layout>
		)
}

export default EditPage
