import React, {useState} from "react"
import PropTypes from "prop-types"
import { getCountries, getIncident, getIncidentTypes, saveIncident } from "../../services/api"
import Loading from "../common/loading"
import "./edit_form.scss"

const EditForm = ({id}) => {
	let endDate = new Date();
	endDate.setDate(new Date().getDate() + 2)
	
	const [data, setData] = useState(id > 0 ? {} : {
		Country: "",
		IncidentType: "",
		Region: "",
		Status: "",
		City: "",
		Description: "",
		ReportingDate: new Date().getFullYear() + "-" + String(new Date().getMonth() + 1).padStart(2, '0') + "-" + String(new Date().getDate()).padStart(2, '0')+ "T" + String(new Date().getHours()).padStart(2, '0')+ ":" +String(new Date().getMinutes()).padStart(2, '0'),
		Impact: "",
		Likelihood: "",
		ExpectedDelay: "",
		StartDate: new Date().getFullYear() + "-" + String(new Date().getMonth()+1).padStart(2, '0') + "-" + String(new Date().getDate()).padStart(2, '0'),
		ExpectedEndDate: endDate.getFullYear() + "-" + String(endDate.getMonth()+1).padStart(2, '0') + "-" + String(endDate.getDate()).padStart(2, '0'),
		//ExpectedEndDate: endDate.getDate() + "-" + (endDate.getMonth()+1) + "-" + endDate.getFullYear(),
		Zipcodes: "",
		Pinned: 0,
		Enabled: 1
	})
	
	const [incident, setIncident] = useState(null)
	const [countries, setCountries] = useState(null)
	const [incidentTypes, setIncidentTypes] = useState(null)
	const [errors, setErrors] = useState([])
	const [errorMessage, setErrorMessage] = useState("")
	const [success, setSuccess] = useState(false)

	const submitIncident = (e) => {
		e.preventDefault()

		let valid = true

		const expectedKeys = ["Country", "IncidentType", "Region", "Status", "City", "Description", "ReportingDate", 
		"Impact", "Likelihood", "ExpectedDelay", "StartDate", "ExpectedEndDate", "Zipcodes", "Pinned"];

		let submitted = data
		
		for(const key in expectedKeys) {
			if(!submitted.hasOwnProperty(expectedKeys[key])) {
				submitted[expectedKeys[key]] = incident[expectedKeys[key]]
			}
		}
		// not sent with incident loading (always enabled)
		if(submitted.hasOwnProperty("Enabled") === false) {
			submitted["Enabled"] = true
		}

		// now validate
		let error_fields = []
		const dateFields = ["StartDate", "ExpectedEndDate"]
		for (const key in dateFields) {
			const field = dateFields[key]
			if(submitted[field] === "" || submitted[field].length < 10) {
				valid = false
				error_fields.push(field)
			} else {
				submitted[field] = submitted[field].substring(0,10)
			}
		}
		
		if(submitted["ReportingDate"] === "" || submitted["ReportingDate"].length < 16) {
			valid = false
			error_fields.push("ReportingDate")
		} else {
			submitted["ReportingDate"] = submitted["ReportingDate"].substring(0,16)
		}

		const mandatoryFields = ["Country", "IncidentType", "Status", "Description", "Impact","ReportingDate", "StartDate", "ExpectedEndDate"]
		for (const key in mandatoryFields) {
			const field = mandatoryFields[key]
			if(submitted[field] === "") {
				valid = false
				error_fields.push(field)
			}
		}

		if(error_fields.length == 0) {
			//submit
			saveIncident(submitted, id).then((response) => {
				if(response.Success) {
					setSuccess(true)
				} else {
					setErrorMessage(response.Error)
				}
				if(typeof window != 'undefined') {
					window.scrollTo(0, 0)
				}
			})
		} else {
			setErrors(error_fields)
		}

	}

	const setCheckboxValue = (e) => {
		const name = e.target.name
		const value = e.target.checked
		
		setData({...data, [name]: value ? 1 : 0})
	}
	const setDataValue = (e) => {
		const {name, value} = e.target
		setData({...data, [name]: value})
	}

	if(countries === null) {
		getCountries().then(countries => {
			setCountries(countries)
		})
	}
	if(incident === null && id > 0) {
		getIncident(id).then(incident => {
			setIncident(incident)
		})
	}

	if(incidentTypes === null) {
		getIncidentTypes(false).then(incidentTypes => {
			setIncidentTypes(incidentTypes)
		})
	}

	if( (incident == null || !incident.ID) && id > 0) {
		return (
			<Loading />
		)
	}

	let countryList = []
	if (countries != null && countries.length > 0) {
		for(const key in countries) {
			countryList.push(
				<option value={countries[key].name} key={key}>{countries[key].name}</option>
				)
		}
	}

	let incidentTypeList = []
	incidentTypeList.push(<option value="" key="0">- Pick Incident Type -</option>)
	if (incidentTypes != null && incidentTypes.length > 0) {
		for(const key in incidentTypes) {
			incidentTypeList.push(
				<option value={incidentTypes[key].name} key={key+1}>{incidentTypes[key].name}</option>
				)
		}
	}

	let successMessage = [];
	if(success) {
		successMessage = (
			<div className="message success">The incident has been stored successfully!</div>
		);
	}
	let showError = [];
	if(errorMessage !== "") {
		showError = (
			<div className="message error">{errorMessage}</div>
		);
	}

  return (
	 	<div className="incident-form">
			 {successMessage}
			 {showError}
			 <form action={"#/adming/edit/" + id} method="post" onSubmit={submitIncident}>
				 <h5>General</h5>
				 <p className={errors.includes('Status') ? "error" : ""}>
					 <label>Incident Status*</label>
					 <select name="Status" onChange={setDataValue} defaultValue={typeof(data.Status) != 'undefined' ? data.Status : incident.Status}>
						<option value="">- Pick status -</option>
						 <option value="current">Current</option>
						 <option value="predicted">Predicted</option>
						</select>
				 </p>
				 <p className={errors.includes('IncidentType') ? "error" : ""}>
					 <label>Incident Type*</label>
					 <select name="IncidentType" onChange={setDataValue} defaultValue={typeof(data.IncidentType) != 'undefined' ? data.IncidentType : incident.IncidentType}>
						 {incidentTypeList}
							</select>
				 </p>
				 <p className={errors.includes('Country') ? "error" : ""}>
					<label>Country*</label>
					<select name="Country" onChange={setDataValue} defaultValue={typeof(data.Country) != 'undefined' ? data.Country : incident.Country}>
						<option value="" key="-1">All countries</option>
						{ countryList }
					</select>
			 </p>
			 <p className={errors.includes('Region') ? "error" : ""}>
				 <label>Region</label>
				 <input type="text" name="Region" onChange={setDataValue} defaultValue={typeof(data.Region) != 'undefined' ? data.Region : incident.Region} />
			 </p>
			 <p className={errors.includes('City') ? "error" : ""}>
				 <label>City</label>
				 <input type="text" name="City" onChange={setDataValue} defaultValue={typeof(data.City) != 'undefined' ? data.City : incident.City} />
			 </p>
			 <h5>Additional information</h5>
			 <p className={errors.includes('Description') ? "error" : ""}>
				 <label>Description</label>
				 <textarea name="Description" onChange={setDataValue} defaultValue={typeof(data.Description) != 'undefined' ? data.Description : incident.Description} />
			 </p>
			 <p className={errors.includes('ReportingDate') ? "error" : ""}>
				 <label>Publication date*</label>
				 <input type="datetime-local" name="ReportingDate" onChange={setDataValue} defaultValue={typeof(data.ReportingDate) != 'undefined' ? data.ReportingDate : incident.ReportingDate.substring(0, 10)} />
				</p>
				<h5>Impact</h5>
				<p className={errors.includes('Impact') ? "error" : ""}>
					<label>Impact*</label>
					<select name="Impact" onChange={setDataValue} defaultValue={typeof(data.Impact) != 'undefined' ? data.Impact : incident.Impact}>
						<option value="low">Low</option>
						<option value="medium">Medium</option>
						<option value="high">High</option>
					</select>
				</p>
				<p className={errors.includes('Likelihood') ? "error" : ""}>
					<label>Likelihood</label>
					<input type="number" step="1" min="0" max="100" name="Likelihood" onChange={setDataValue} defaultValue={typeof(data.Likelihood) != 'undefined' ? data.Likelihood : incident.Likelihood} />
				</p>
				<p className={errors.includes('ExpectedDelay') ? "error" : ""}>
					<label>Delay</label>
					<input type="number" step="1" min="0" max="1000" name="ExpectedDelay" onChange={setDataValue} defaultValue={typeof(data.ExpectedDelay) != 'undefined' ? data.ExpectedDelay : incident.ExpectedDelay} /> <span>days</span>
				</p>
				<p className={errors.includes('StartDate') ? "error" : ""}>
					<label>Start date*</label>
					<input type="date" name="StartDate" onChange={setDataValue} defaultValue={typeof(data.StartDate) != 'undefined' ? data.StartDate : incident.StartDate.substring(0, 10)} />
				</p>
				<p className={errors.includes('ExpectedEndDate') ? "error" : ""}>
					<label>Expected end date*</label>
					<input type="date" name="ExpectedEndDate" onChange={setDataValue} defaultValue={typeof(data.ExpectedEndDate) != 'undefined' ? data.ExpectedEndDate : incident.ExpectedEndDate.substring(0, 10)} />
				</p>
				<p className={errors.includes('Zipcodes') ? "error" : ""}>
					<label>Afffected postcode(s)</label>
					<textarea name="Zipcodes" onChange={setDataValue} defaultValue={typeof(data.Zipcodes) != 'undefined' ? data.Zipcodes : incident.Zipcodes} />
				</p>
				<p className={errors.includes('Pinned') ? "error checkbox" : "checkbox"}>
					<label>
					<input type="checkbox" name="Pinned" value="1" onChange={setCheckboxValue} checked={typeof(data.Pinned) != 'undefined' ? data.Pinned : incident.Pinned} />
					Pin this alert</label>
				</p>
				<p className={errors.includes('Enabled') ? "error checkbox" : "checkbox"}>
					<label>
					<input type="checkbox" name="Enabled" value="1" onChange={setCheckboxValue} checked={typeof(data.Enabled) != 'undefined' ? data.Enabled : true} />
					Enabled
					</label>
				</p>
				<p className="submit">
					<input type="submit" value="Save" className="button" />
				</p>
			 </form>
	 	</div>
  )
}

EditForm.propTypes = {
	  id: PropTypes.number.isRequired
}

export default EditForm